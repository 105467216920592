import styled from '@emotion/styled';
import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from 'ui';

export const GlobalHeader = styled(EuiHeader)`
  padding: 0px 20px 0px 15px;
  flex-shrink: 0;
  &.euiTab {
    cursor: pointer;
    &:focus {
      background-color: transparent;
      text-decoration: none;
    }
  }
`;

interface IHeaderProps {
  isOldUI: boolean;
  logo: {
    width: string;
    height: string;
    margin: string;
  };
}

export const HeaderLogo = styled(EuiHeaderSection, {
  shouldForwardProp: (prop) => prop !== 'logo',
})<IHeaderProps>`
  svg {
    width: ${(props) => props?.logo?.width};
    height: ${(props) => props?.logo?.height};
    margin: ${(props) => (props?.isOldUI ? props?.logo?.margin : 0)};
  }
`;

export const IconContainer = styled(EuiHeaderSectionItem)`
  margin-right: 8px;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const navPageStyle: React.CSSProperties = {
  backgroundColor: 'white',
  position: 'relative',
  top: '48px',
};

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Asset,
  AssetName,
  EuiFlexGroup,
  EuiFlexItem,
  EuiShowFor,
  EuiSpacer,
  Icon,
  useEuiBackgroundColor,
  useEuiTheme,
} from 'ui';
import { AdminSettingsDropdown } from '@app/components/AdminSettingsDropdown/AdminSettingsDropdown';
import { useChatContext } from '@app/components/Chat/ChatProvider';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { getAccountSubmissionOrg, userHasSubmissionAccess } from '@app/cx/Dashboard/helpers';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { OrgLevelType } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { useWhiteLabel } from '@app/hooks/useWhiteLabel/useWhiteLabel';
import {
  FEATURE_TYPE_CASUALTY_ONLY,
  FEATURE_TYPE_DOCUMENTS_PORTAL,
  FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
} from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { FEATURE_TYPE_ENTERPRISE_REPORT } from '../../../platform/SystemSettings/Flags/types';
import { pathToTab, tabToPath } from '../Layout';
import { HeaderLogo } from '../Layout.emotion';
import {
  ALERTS_CONFIG_ITEM,
  CASUALTY_ITEM,
  COPILOT_ITEM,
  HOME_ITEM,
  HUB_ITEM,
  INBOX_ITEM,
  LIBRARY_ITEM,
  PRECHECK_ITEM,
  PROJECTS_ITEM,
  STREAMS_ITEM,
  SUBMISSIONS_ITEM,
  SUPPORT_ITEM,
  USERS_ITEM,
} from './constants';
import {
  MenuOptionsContainer,
  StyledButtonIcon,
  StyledListGroup,
  StyledLogoContainer,
  StyledSideNav,
  UserMenuContainer,
} from './SideNav.emotion';
import { useCmsNav } from './useCmsNav';
import { MobileSideNav } from './MobileSideNav';

export const SideNav = () => {
  const { account } = useAuth();
  const { euiTheme } = useEuiTheme();
  const { selectedOrganization } = useUserSession();
  const { roomSubscriptionsMap } = useChatContext();

  let unreadTickets = 0;
  Object.keys(roomSubscriptionsMap).forEach((roomID) => {
    if (roomSubscriptionsMap[roomID].prid === '65cfddfe7f72c9a855c826a5') {
      unreadTickets += roomSubscriptionsMap[roomID].unread;
    }
  });

  const cmsItems = useCmsNav();

  const history = useHistory();
  const location = useLocation();
  const tracker = useTracker();

  // TODO move this to a hook and up the component tree. To prevent calling this multiple times in different places.
  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  // The presence of this permission (Portfolio:View) indicates that the user sees 'Portfolio' instead of 'My Properties'.
  // Its also always set for everyone, except for Contributors.
  const isContributor = !orgInfoData?.orgInfo?.permissionsV2?.find((permission) => {
    return permission.resource === 'Portfolio' && permission.action === 'View';
  });
  const isEditor = !orgInfoData?.orgInfo?.permissions?.canManageRoles?.includes('Editor');
  let streams_item = { ...STREAMS_ITEM };
  streams_item.label = !isContributor ? streams_item.label : 'My Properties';

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [_, setSelectedItem] = useState(pathToTab(location));
  // FIX ME
  // @ts-ignore
  const { assetName, assetLink, logoStyle } = useWhiteLabel(account, isCollapsed);

  const isAdmin = account?.permissions?.admin;
  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canViewProjects = permissions.includes('canManageProjects');
  const canManageUsers =
    selectedOrganization?.enabledFeatures?.includes('SelfServiceUserMgmt') &&
    (isAdmin || permissions.includes('canManageUsers'));
  const canViewAccounts = permissions?.includes('canViewAccounts');

  const canViewDocuments =
    isAdmin ||
    (selectedOrganization?.enabledFeatures?.includes(FEATURE_TYPE_DOCUMENTS_PORTAL) &&
      permissions.includes('canManageDocuments'));

  const submissionOrgs = account?.submissionCreateOrgs || [];
  const hasSubmissionAccessForCurrentOrg = userHasSubmissionAccess(
    submissionOrgs,
    selectedOrganization,
  );
  // true if primary org is submission org, else false
  const primaryOrgIsSubmissionOrg = !!getAccountSubmissionOrg(account);
  const canManageProperties = permissions?.includes('canManageProperties');
  const casualtyOnly = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_CASUALTY_ONLY,
  );

  const enableAccountDashboard =
    (isAdmin && selectedOrganization?.enableAccountDashboard) ||
    hasSubmissionAccessForCurrentOrg ||
    (primaryOrgIsSubmissionOrg && !isAdmin) ||
    canViewAccounts;

  const isEnterpriseReportEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_ENTERPRISE_REPORT,
  );

  const isHubAnalyticsForAdminsEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_HUB_ANALYTICS_ADMIN_ONLY,
  );

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const isAdminAndHubAnalyticsEnabled = isAdmin && isHubAnalyticsForAdminsEnabled;

  const orgInfoPermissions = orgInfoData?.orgInfo?.permissions.viewEnterpriseReports;

  let items = [
    ...(!isContributor
      ? [
          orgInfoPermissions && (isEnterpriseReportEnabled || isAdminAndHubAnalyticsEnabled)
            ? HUB_ITEM(history, selectedOrganization?.name)
            : HOME_ITEM,
        ]
      : []),
    ...(selectedOrganization?.enablePreCheck && canManageProperties && !isContributor
      ? [
          {
            ...PRECHECK_ITEM,
          },
        ]
      : []),
    ...(selectedOrganization?.orgLevel === OrgLevelType.Enterprise ? [] : [streams_item]), // Everyone gets streams
    ...(selectedOrganization?.enablePreCheck && canManageProperties ? [INBOX_ITEM()] : []),
    ...(enableAccountDashboard ? [SUBMISSIONS_ITEM] : []),
    ...(canViewProjects ? [PROJECTS_ITEM] : []),
    ...(canManageUsers ? [USERS_ITEM] : []),
    ...(canViewDocuments ? [LIBRARY_ITEM] : []),
    ...(isAdmin ? [COPILOT_ITEM] : []),
    ...(canManageProperties && !isContributor && !isEditor ? [ALERTS_CONFIG_ITEM] : []),
    SUPPORT_ITEM(unreadTickets),
  ];
  if (casualtyOnly) {
    items = [
      orgInfoPermissions && (isEnterpriseReportEnabled || isAdminAndHubAnalyticsEnabled)
        ? HUB_ITEM(history, selectedOrganization?.name)
        : HOME_ITEM,
      ...(canManageUsers ? [USERS_ITEM] : []),
      CASUALTY_ITEM,
      ...(canViewDocuments ? [LIBRARY_ITEM] : []),
      SUPPORT_ITEM(unreadTickets),
    ];
  }

  const finalItems = [...items, ...cmsItems].map((item) => ({
    'data-testid': `header-${item.id}-tab`,
    href: item.path === null ? null : tabToPath(item.path),
    icon: item.icon,
    id: item.id,
    isActive: item.path === location.pathname.split('/')?.[3],
    label: isCollapsed ? undefined : item.label,
    onClick: (e: any) => {
      // Don't follow link!
      e.preventDefault();
      if (item.onClick) {
        item.onClick();
      } else {
        onSelectedItemChanged(item);
      }
    },
  }));

  const onSelectedItemChanged = (val: { id: string; label: string; path: string }) => {
    setSelectedItem(val);
    const context: any = {};
    if (val.id === 'precheck') {
      context.event_surface = 'PreCheck';

      context.organization_id = selectedOrganization?.id;
    }

    tracker.track(`${val.label} Navigation Item Clicked`, context);

    const navigationOrgName =
      val.id === 'accounts' && selectedOrganization?.orgLevel === 'Underlying'
        ? account?.submissionCreateOrgs?.[0]?.name
        : selectedOrganization?.name;

    history.push({
      pathname: tabToPath(val.path, navigationOrgName),
    });
  };

  const displayLogo = !isCollapsed || (isCollapsed && assetName === AssetName.logoAlt);
  return (
    <>
      <EuiShowFor sizes={['xs', 's', 'm']}>
        <MobileSideNav
          items={finalItems}
          adminComponent={
            isAdmin ? (
              <AdminSettingsDropdown
                isCollapsed={isCollapsed}
                isOpen={isUserMenuOpen}
                setIsOpen={setIsUserMenuOpen}
              />
            ) : null
          }
        />
      </EuiShowFor>

      <EuiShowFor sizes={['l', 'xl']}>
        <StyledSideNav
          isDocked
          isOpen={false}
          onClose={null}
          maxWidth={isCollapsed ? '50px' : '200px'}
          backgroundColor={useEuiBackgroundColor('subdued')}
        >
          <EuiFlexGroup alignItems="center" gutterSize="none" justifyContent="spaceBetween">
            {displayLogo && (
              <StyledLogoContainer isCollapsed={isCollapsed} grow={false}>
                <HeaderLogo {...logoStyle} side="left">
                  {assetLink ? (
                    <img src={assetLink} />
                  ) : (
                    <Asset name={assetName} color={euiTheme.colors.primary} />
                  )}
                </HeaderLogo>
              </StyledLogoContainer>
            )}
            <EuiFlexItem grow={false}>
              <StyledButtonIcon
                iconName={isCollapsed ? 'chevronRight' : 'chevronLeft'}
                size="m"
                onClick={() => setIsCollapsed(!isCollapsed)}
                isCollapsed={isCollapsed}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <MenuOptionsContainer>
            <EuiFlexItem grow={false}>
              <StyledListGroup aria-label="side navigation links" listItems={finalItems} size="m" />
            </EuiFlexItem>
            <UserMenuContainer>
              {isAdmin && (
                <AdminSettingsDropdown
                  isCollapsed={isCollapsed}
                  isOpen={isUserMenuOpen}
                  setIsOpen={setIsUserMenuOpen}
                />
              )}
              <StyledListGroup
                listItems={[
                  {
                    // FIX ME
                    // @ts-ignore
                    'data-testid': 'logout-menu-item',
                    icon: (
                      <EuiFlexGroup>
                        <Icon name="logOut" />
                      </EuiFlexGroup>
                    ),
                    id: 'sign-out',
                    label: 'Sign Out',
                    onClick: () => {
                      history.push({ pathname: '/logout' });
                    },
                  },
                ]}
                size="m"
              />
            </UserMenuContainer>
          </MenuOptionsContainer>
        </StyledSideNav>
      </EuiShowFor>
    </>
  );
};

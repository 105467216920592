import React, { useEffect } from 'react';
import { useUserSession } from '@app/contexts/UserSessionContext';
import DocumentUpload from '@app/platform/Library/DocumentUpload/DocumentUpload';
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, Button } from 'ui';
import { StyledContainer, StyledDescription } from './Documents.emotion';
import { useUploads } from '@app/contexts/UploadsContext';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useTaskContext } from './context/TaskContext';
import { DocumentBadge } from './DocumentBadge';

export const UploadDocuments = ({ propertyID, recommendedDocs, disabled }) => {
  const { selectedOrganization } = useUserSession();
  const { uploads } = useUploads();
  const { refetch } = useTaskContext();
  const loading = Object.values(uploads).some((upload) => upload.progress === 0);

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 4000);
  }, [Object.values(uploads).length]);

  const renderDocsBadges = () => {
    return (
      <EuiFlexGroup wrap gutterSize="s">
        {recommendedDocs.map((doc) => (
          <DocumentBadge doc={doc} />
        ))}
      </EuiFlexGroup>
    );
  };

  if (disabled) {
    return (
      <div>
        <p style={{ fontSize: 14, marginBottom: 8, fontWeight: 'bold' }}>
          {' '}
          Recommended documents to upload
        </p>
        {renderDocsBadges()}
      </div>
    );
  }

  return (
    <DocumentUpload organizationId={selectedOrganization.id} propertyID={propertyID}>
      <StyledContainer>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem>
            <StyledDescription>
              <EuiIcon style={{ marginBottom: 4 }} type="sparkles" /> Save Time by Autofilling From
              Documents
            </StyledDescription>

            <EuiSpacer size="s" />
            {renderDocsBadges()}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {loading ? <LoadingSpinnerV2 /> : <Button label="Upload" />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </StyledContainer>
    </DocumentUpload>
  );
};

import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useGetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getTasks.generated';
import { useQueryState } from '@app/hooks/useQueryState';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { useGetAccountUsersQuery } from '@app/graphql/queries/rbac/__generated__/AccountUsers.generated';
import { Role } from '@app/graphql/types';
import { TaskStatus, ValuationOutlierReason } from '@app/graphql/precheck/precheck.types';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { colorMap } from '../constants/colorMap';
import { labelMap } from '../constants/labelMap';
import { AttritionalReason, CATReasons, COPEReason, IFormattedtask, ITaskContext } from './types';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';

export const TaskContext = createContext({} as ITaskContext);

export const TaskProvider = ({ withUsers = false, children }) => {
  const client = usePrecheckClient();
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const { stream } = useStreamContext();
  const tracker = useTracker();

  const [_qs, setQueryState] = useQueryState();
  const location = useLocation();
  const [selectedTasks, setSelectedTasks] = useState([]);

  const qs: any = queryString.parse(location.search);

  const name = qs.name;
  const users = qs?.users?.length > 0 ? qs?.users?.split(',') : [];
  const documents = qs?.documents?.length > 0 ? qs?.documents?.split(',') : [];
  const reasons = qs?.reasons?.length > 0 ? qs?.reasons?.split(',') : [];
  const dismissed = qs?.dismissed && qs?.dismissed === 'true';

  const docOptions = {};
  const reasonOptions = {};
  const taskTotals = {
    attritional: {
      tiv: 0,
      properties: 0,
    },
    cat: {
      tiv: 0,
      properties: 0,
    },
    cope: {
      tiv: 0,
      properties: 0,
    },
    valuation: {
      tiv: 0,
      properties: 0,
    },
  };

  const { data, loading, error, refetch } = useGetTasksQuery({
    client,
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        orgName: selectedOrganization.name,
      },
    },
  });

  const { data: availableUsers } = useGetAccountUsersQuery({
    skip: !withUsers,
    variables: {
      input: {
        limit: 1500,
        offset: 0,
        orgName: selectedOrganization.name,
        // FIX ME
        // @ts-ignore
        roleFilter: [Role.RiskManager, Role.Editor],
      },
    },
  });

  const [sortField, setSortField] = useState('priority');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const userOptions = (availableUsers?.accountUsers || []).reduce(
    (acc, user) => {
      if (user.profile.email !== account.email) {
        acc.push({
          label: `${
            user?.profile?.givenName && user?.profile?.familyName
              ? `${user.profile.givenName} ${user.profile.familyName.replace(
                  'temporarily_unavailable',
                  '',
                )}`
              : user.profile.email
          }`,
          value: user.profile.email,
        });
      }

      return acc;
    },
    [{ label: `${account.givenName} ${account.familyName}`, value: account.email }],
  );

  const transformedTasks = (data?.getTasks?.tasks || []).map((task, index) => ({
    assignees: (task.assignees || []).map((assignee) => ({
      email: assignee?.assignee,
      name: assignee?.userInfo
        ? `${assignee?.userInfo?.givenName} ${assignee?.userInfo?.familyName?.replace(
            'temporarily_unavailable',
            '',
          )}`
        : null,
    })),
    status: task.status,
    attributes: task.propertyRecommendation.attribute || [],
    id: task.id,
    priority: index + 1,
    propertyId: task.propertyRecommendation.archipelagoID,
    propertyUid: task.property.id,
    propertyName:
      task.property.locationName ||
      task.property.streetAddress ||
      task.propertyRecommendation.archipelagoID,
    propertyCityAndState: task.property.city
      ? `${task.property.city || ''}, ${task.property.state || ''} ${
          task.property.postalCode || ''
        }`
      : '',
    tiv: formatStreamCurrency(task.property.totalInsuredValueDisplay || 0),
    rawTiv: task.property.totalInsuredValueDisplay || 0,
    reasons: Object.keys(
      (task.propertyRecommendation.attribute || []).reduce((acc, attribute) => {
        attribute.reasons?.map((reason) => {
          if (reason === 'Valuation Outlier') {
            reasonOptions[task.propertyRecommendation.valuationOutlierReason] =
              task.propertyRecommendation.valuationOutlierReason;
            acc[task.propertyRecommendation.valuationOutlierReason] =
              task.propertyRecommendation.valuationOutlierReason;
          } else {
            reasonOptions[reason] = reason;
            acc[reason] = reason;
          }
        });

        return acc;
      }, {}),
    ),
    recommendedDocuments: Object.keys(
      (task.propertyRecommendation.attribute || []).reduce((acc, attribute) => {
        attribute.documents?.map((doc) => {
          docOptions[doc] = doc;
          acc[doc] = doc;
        });

        return acc;
      }, {}),
    ),

    propertyDocuments: task.property.documents || [],
    property: task.property,
  }));

  let readyTasksCount = 0;
  let filteredTiv = 0;

  const filteredTasks = transformedTasks.filter((task) => {
    if (task.status === TaskStatus.Ready) {
      readyTasksCount++;

      const hasCat = Object.values(CATReasons).some((reason) => task.reasons.includes(reason));
      if (hasCat) {
        taskTotals.cat.properties++;
        taskTotals.cat.tiv += task.rawTiv;
      }

      task.reasons.forEach((reason) => {
        if (reason === AttritionalReason.attritional) {
          taskTotals.attritional.properties++;
          taskTotals.attritional.tiv += task.rawTiv;
        }

        if (reason === COPEReason.cope) {
          taskTotals.cope.properties++;
          taskTotals.cope.tiv += task.rawTiv;
        }

        if (
          reason === ValuationOutlierReason.Overvalued ||
          reason === ValuationOutlierReason.Undervalued
        ) {
          taskTotals.valuation.properties++;
          taskTotals.valuation.tiv += task.rawTiv;
        }
      });
    }

    const nameMatch =
      name?.length > 0
        ? task.propertyName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) ||
          task.propertyCityAndState.toLocaleLowerCase().includes(name.toLocaleLowerCase())
        : true;

    const assigneeMatch =
      users.length === 0
        ? true
        : users.some((user) => task.assignees.some((assignee) => assignee.email === user));

    const docMatch =
      documents.length === 0
        ? true
        : documents.some((doc) => task.recommendedDocuments.includes(doc));

    const reasonMatch =
      reasons.length === 0 ? true : reasons.some((reasons) => task.reasons.includes(reasons));

    const dismissedMatch = dismissed
      ? task.status === TaskStatus.Dismissed
      : task.status === TaskStatus.Ready;

    if (nameMatch && assigneeMatch && docMatch && dismissedMatch && reasonMatch) {
      filteredTiv += task.rawTiv;
      return true;
    } else {
      return false;
    }
  });

  const filtersApplied =
    name?.length > 0 ||
    users.length !== 0 ||
    documents.length !== 0 ||
    dismissed ||
    reasons.length > 0;

  return (
    <TaskContext.Provider
      value={{
        error,
        readyTasksCount,
        filters: {
          userOptions,
          name,
          users,
          reasons,
          dismissed,
          filtersApplied,
          documents,
          reasonOptions: Object.keys(reasonOptions).map((reason: any) => ({
            label: labelMap[reason],
            value: reason,
            color: colorMap[reason],
          })),
          documentOptions: Object.keys(docOptions).map((doc) => ({ label: doc, value: doc })),
          setFilter: (filterName, filterValue) => {
            tracker.track('Pre-Check: Filter Applied', {
              filterName,
              filterValue,
            });
            setQueryState({ [filterName]: filterValue as string });
          },
          clearFilters: () => {
            setQueryState({
              name: null,
              users: null,
              hazards: null,
              category: null,
              documents: null,
              dismissed: null,
              reasons: null,
            });
          },
        },
        loading: loading,
        refetch,
        setSelectedTasks,
        selectedTasks,
        tasks: filteredTasks as Array<IFormattedtask>,
        filteredTiv: formatStreamCurrency(filteredTiv),
        totalTiv: stream?.totalInsuredValue || 0,
        tivPercent: `${Math.round((filteredTiv / stream?.totalInsuredValue) * 100)}%`,
        taskTotals,
        sortField,
        setSortField,
        sortDirection,
        setSortDirection,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => useContext(TaskContext);

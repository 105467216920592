import React from 'react';
import { EuiBadge, EuiHorizontalRule, EuiToolTip, Icon } from 'ui';
import { docMap } from './constants/docMap';

export const DocumentBadge = ({ doc }) => {
  const toolTipContent = docMap[doc] ? (
    <div>
      <span style={{ fontWeight: 700 }}>{doc}</span>
      <EuiHorizontalRule margin="xs" color="#000" />
      <span dangerouslySetInnerHTML={{ __html: docMap[doc] }} />
    </div>
  ) : (
    doc
  );
  return (
    <EuiToolTip content={toolTipContent}>
      <EuiBadge style={{ marginLeft: 0 }}>
        <span style={{ marginRight: '8px' }}>{doc}</span>

        <Icon color="#fff" name="help" size="s" />
      </EuiBadge>
    </EuiToolTip>
  );
};
